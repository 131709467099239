<template>
  <section id="referral">
    <h1 id="page-title">{{ $t('bring_your_friends') }}</h1>
    <div class="text-muted">{{ $t('bring_your_friends_details') }}</div>
    <div class="spacecard blue md mt-5" v-if="link">
      <h2>{{ $t('invitation_link') }}</h2>
      <div class="row">
        <div class="col-sm">
          <input class="form-control link-copy" readonly v-model="link"/>
        </div>
        <div class="col-sm-auto mt-3 mt-sm-0">
          <button class="btn-themed d-block d-sm-inline-block w-mob-100" @click="copy">{{ $t('copy') }}</button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col" v-for="(s, sind) in steps" :key="`step-${sind}`">
          <div class="pr-2">
            <h3>{{ $t('step') }} {{ (sind + 1) }}</h3>
            <div class="text-muted small">{{ $t(s) }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {StudentPageService} from "../../../../services/api.service"

export default {
  name: "StudentReferral",
  data() {
    return {
      steps: [
        'share_link_with_friend',
        'friend_opens_link',
        'friend_pays_for_classes',
      ],
      link: null,
    }
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.link).then(() => {
        this.$success(this.$t('copied'))
      }).catch((e) => {
        console.log(e)
      })
    },
    loadReferralLink() {
      StudentPageService.getReferralLink().then(res => {
        this.link = res.data.data
      })
    }
  },
  mounted() {
    this.loadReferralLink()
  }
}
</script>

<style scoped>
#referral {
  max-width: 600px;
}
.link-copy {
  background: #fff;
  height: 45px;
  line-height: 45px;
  padding: 0 16px;
}
@media(max-width: 768px) {
  .link-copy {
    height: 40px;
    line-height: 40px;
  }
}
</style>
